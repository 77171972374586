import React, { useState } from 'react'
import { Button, message } from 'antd'
import './index.less'

const { Loader, Service, BaseStore, LM_DB, Utils } = window

const Upload = Loader.loadBusinessComponent('UploadComponents', 'Upload')
const IconFont = Loader.loadBaseComponent('IconFont')
const PictureView = Loader.loadBaseComponent('PictureView')

export default function personSearch() {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setUrl] = useState(null)
  const [rects, setRects] = useState([])
  const uploadAction = function(formData) {
    setLoading(true)
    return Service.face.uploadImg(formData)
  }
  const uploadSuccess = async function(url, isSet = true) {
    const options = {}
    if (isSet) {
      setUrl(url)
      options.url = url
    } else {
      options.base64 = url.replace('data:image/png;base64,', '')
    }
    const res = await Service.face.getFeature(options).catch(() => ({ data: { list: [] } }))
    const featureList = res.data.list || []
    if (featureList.length === 0) {
      setLoading(false)
      return message.warn('未获取到特征！')
    }
    const arr = featureList.map(v => ({ type: 'face', feature: v.feature, value: `${v.rect.left},${v.rect.top},${v.rect.width},${v.rect.height}` }))

    setLoading(false)
    if (featureList.length === 1) {
      const item = arr[0]
      const id = Utils.uuid()
      await LM_DB.add('parameter', { id, frameUrl: url, url, feature: item.feature, rects: arr, defaultRect: item })
      BaseStore.tab.goPage({ moduleName: 'epidemicPersonSearchList', data: { id, isSearch: true } })
    } else {
      !isSet && setUrl(url)
      setRects(() => arr)
    }
  }
  const getSelectRectResult = async func => {
    const url = await func()
    uploadSuccess(url, false)
  }
  const onClickDefaultRect = async (item, url) => {
    const id = Utils.uuid()
    try {
      await LM_DB.add('parameter', { id, frameUrl: imageUrl, url, feature: item.feature, rects, defaultRect: item })
      BaseStore.tab.goPage({ moduleName: 'epidemicPersonSearchList', data: { id, isSearch: true } })
    } catch (e) {
      console.error(e)
    }
  }

  function clearImage() {
    setRects([])
    setUrl(null)
  }
  return (
    <div className="epidemic-person-search">
      <div className="epidemic-person-image">
        <img src="/resource/image/epidemic/bg.png" style={{ width: 469 }} alt="" />
      </div>
      <div className="upload-wrapper">
        {rects.length > 0 ? (
          <>
            <PictureView
              imagePath={imageUrl}
              key={imageUrl}
              showDefaultRect={false}
              showRect={true}
              rects={rects}
              onClickDefaultRect={onClickDefaultRect}
            >
              {options => {
                const { getSelectResult, changeSelectStatus, isOpenSelect, isOverSelect } = options
                return (
                  <div className="select-img-prcture-footer">
                    <Button className="footer-button" onClick={() => changeSelectStatus(!isOpenSelect)}>
                      <IconFont type="icon-S_View_SearchBox" />
                      {isOpenSelect ? '取消' : '手动'}框选
                    </Button>
                    {isOpenSelect && isOverSelect && (
                      <Button type="primary" className="footer-button" onClick={() => getSelectRectResult(getSelectResult)}>
                        <IconFont type="icon-S_Edit_ImgSearch" />
                        搜索截图
                      </Button>
                    )}
                  </div>
                )
              }}
            </PictureView>
            <IconFont className="close-select-face" type="icon-S_Edit_Close" onClick={clearImage} />
          </>
        ) : (
          <UploadConponent uploadAction={uploadAction} uploadSuccess={uploadSuccess} />
        )}
        {loading && (
          <div spinning={true} className="upload-loading">
            <IconFont type="icon-S_Edit_Refresh" />
            <span>正在上传图片</span>
          </div>
        )}
      </div>
    </div>
  )
}

function UploadConponent({ uploadAction, uploadSuccess }) {
  return (
    <>
      <Upload childView={UpLoadContent} cssname="upload-wrapper-one" uploadAction={uploadAction} changeheadImg={uploadSuccess} disableImage={true} />
      <Upload childView={UploadButton} cssname="upload-wrapper-two" uploadAction={uploadAction} changeheadImg={uploadSuccess} disableImage={true} />
    </>
  )
}

function UpLoadContent() {
  return (
    <div className="upload-content-wrapper">
      <IconFont type="icon-L_Bar_AddImg" />
      <span>拖拽图片到这来搜图</span>
    </div>
  )
}

function UploadButton() {
  return <Button>本地上传图片</Button>
}
